import { Controller } from "@hotwired/stimulus"
import { Modal } from 'flowbite';

export default class extends Controller {
  static targets = ["headerRow", "yearDisplay", "metricRow", "thisWeekButton", "editConfirmationModal", "editConfirmationMessage"]
  static values = {
    currentYear: Number,
    metrics: Array,
    scorecardId: String,
    activeCell: Object
  }

  connect() {
    this.debug = false; // Set this to false in production
    if (this.debug) console.log('connect');

    this.currentDate = new Date()
    this.currentWeekStartDate = this.getCurrentWeekStartDate()
    this.earliestGeneratedDate = new Date(this.currentDate)
    this.latestGeneratedDate = new Date(this.currentDate)
    this.currentYearValue = this.currentDate.getFullYear()
    this.isLoading = false
    this.generateInitialContent()
    this.setupInfiniteScroll()

    // Disable the "This Week" button on page load
    this.disableThisWeekButton()

    this.editConfirmationModal = new Modal(this.editConfirmationModalTarget, {
      backdrop: 'static',
      closable: false,
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40'
    });
    this.lastConfirmationTime = this.getLastConfirmationTime();
    this.setupEditingObserver()
  }

  setupEditingObserver() {
    // Create a single observer for both attribute changes and new cells
    const observer = new MutationObserver((mutations) => {
      let cellsToSetup = new Set()

      mutations.forEach((mutation) => {
        // Handle attribute changes
        if (mutation.type === 'attributes' &&
          mutation.attributeName === 'data-is-editing' &&
          mutation.target.dataset.isEditing === 'true') {
          cellsToSetup.add(mutation.target)
        }

        // Handle new cells
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeName === 'TD' && node.dataset.isEditing === 'true') {
              cellsToSetup.add(node)
            }
          })
        }
      })

      // Process all cells that need setup in one batch
      cellsToSetup.forEach(cell => {
        if (this.debug) {
          console.log('Setting up cell:', {
            cell,
            metricId: cell.dataset.metricId,
            startDate: cell.dataset.startDate,
            value: cell.dataset.cellValue
          })
        }
        this.setupCellInput(cell)
      })
    })

    // Observe the table with all needed options at once
    observer.observe(this.element, {
      attributes: true,
      attributeFilter: ['data-is-editing'],
      childList: true,
      subtree: true
    })

    // Check existing cells
    this.element.querySelectorAll('td[data-is-editing="true"]').forEach(cell => {
      this.setupCellInput(cell)
    })

    return observer
  }

  disconnect() {
    // ... existing disconnect code if any ...
    this.editingObserver?.disconnect()
  }

  async generateInitialContent() {
    if (this.debug) console.log('generateInitialContent');
    const previousMonth = this.getPreviousMonth(this.currentDate)
    const nextMonth = this.getNextMonth(this.currentDate)
    const currentMonth = this.getCurrentMonth()

    await this.generateMonthContent(previousMonth, 'past')
    await this.generateMonthContent(currentMonth)
    await this.generateMonthContent(nextMonth)

    this.scrollToCurrentWeek();
  }

  async generateMonthContent(date, direction = 'future') {
    if (this.debug) console.log('generateMonthContent');
    const weeksInMonth = this.getWeeksInMonth(date)

    const headers = this.generateMonthHeaders(weeksInMonth)
    const cells = await this.generateMetricCells(weeksInMonth)

    if (direction === 'past') {
      this.headerRowTargets.forEach(headerRow => {
        const firstHeader = headerRow.firstElementChild
        if (firstHeader) {
          firstHeader.insertAdjacentHTML('afterend', headers)
        } else {
          headerRow.insertAdjacentHTML('afterbegin', headers)
        }
      })
      this.metricRowTargets.forEach((row, index) => {
        const firstCell = row.firstElementChild
        if (firstCell) {
          firstCell.insertAdjacentHTML('afterend', cells[index])
        } else {
          row.insertAdjacentHTML('afterbegin', cells[index])
        }
      })
    } else {
      this.headerRowTargets.forEach(headerRow => {
        headerRow.insertAdjacentHTML('beforeend', headers)
      })
      this.metricRowTargets.forEach((row, index) => {
        row.insertAdjacentHTML('beforeend', cells[index])
      })
    }

    if (direction === 'past') {
      this.earliestGeneratedDate = date
    } else {
      this.latestGeneratedDate = date
    }
  }

  getWeeksInMonth(date) {
    if (this.debug) console.log('getWeeksInMonth');
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const startingWeek = { weekStart: firstDay, weekEnd: this.getNextSunday(firstDay) }
    let weeks = [startingWeek]

    let currentWeek = startingWeek
    while (currentWeek.weekEnd < lastDay) {
      const nextMonday = this.getNextMonday(currentWeek.weekEnd)
      currentWeek = { weekStart: nextMonday, weekEnd: this.getNextSunday(nextMonday) }
      if (currentWeek.weekEnd > lastDay) {
        currentWeek.weekEnd = lastDay
      }
      weeks.push(currentWeek)
    }
    return weeks
  }

  generateMonthHeaders(weeksInMonth) {
    if (this.debug) console.log('generateMonthHeaders');
    let headers = ''

    weeksInMonth.forEach(week => {
      headers += this.createHeaderCell(week.weekStart, week.weekEnd)
    })

    headers += this.createMonthSummaryHeaders(weeksInMonth[0].weekStart)
    return headers
  }

  getNextMonday(date) {
    if (this.debug) console.log('getNextMonday');
    const result = new Date(date)
    result.setDate(date.getDate() + (8 - date.getDay()) % 7)
    return result
  }

  getNextSunday(date) {
    if (this.debug) console.log('getNextSunday');
    const result = new Date(date)
    result.setDate(date.getDate() + (7 - date.getDay()) % 7)
    return result
  }

  async generateMetricCells(weeksInMonth, currentMonth = false) {
    if (this.debug) console.log('generateMetricCells');
    const metricValues = await this.fetchMetricValues(weeksInMonth[0].weekStart, weeksInMonth[weeksInMonth.length - 1].weekEnd, this.metricsValue.map(m => m.id));

    return this.metricRowTargets.map(row => {
      const metricId = row.dataset.metricId;
      let cells = '';

      weeksInMonth.forEach(week => {
        cells += this.createMetricCell(metricId, week.weekStart, week.weekEnd, metricValues);
      });

      // Add summary cells at the end of the month
      const lastWeekEnd = weeksInMonth[weeksInMonth.length - 1].weekEnd;
      const summaryValue = metricValues[metricId] && metricValues[metricId][this.formatDateToYYYYMMDD(lastWeekEnd) + '-aggregation'];
      const goalValue = metricValues[metricId] && metricValues[metricId][this.formatDateToYYYYMMDD(lastWeekEnd) + '-goal'];
      cells += this.createMonthSummaryCells(metricId, lastWeekEnd, summaryValue, goalValue);

      return cells;
    });
  }

  createMetricCell(metricId, startDate, endDate, metricValues) {
    if (this.debug) console.log('createMetricCell');
    const formattedStartDate = this.formatDateToYYYYMMDD(startDate);
    const metricValue = metricValues[metricId] && metricValues[metricId][formattedStartDate] ? metricValues[metricId][formattedStartDate] : null;
    return this.createCell(metricId, startDate, metricValue, false);
  }

  createMonthSummaryCells(metricId, startDate, summaryValue, goalValue) {
    if (this.debug) console.log('createMonthSummaryCells', startDate, metricId);
    const summaryCellHtml = this.createCell(metricId, startDate, summaryValue, true);
    const goalCellHtml = this.createGoalCell(metricId, startDate, goalValue);
    return summaryCellHtml + goalCellHtml;
  }

  createCell(metricId, startDate, metricValue, isSummary = false, isEditing = false) {
    if (this.debug) console.log('createCell');
    const metric = this.metricsValue.find(m => m.id.toString() === metricId.toString());
    const isEditable = !isSummary && metric.data_type !== 'calculated' && metric.data_type !== 'prior_period_growth';
    const editableAttributes = isEditable ? `data-action="click->scorecard-infinite-scroll-table#editCell"` : '';

    // Get the Monday of the week for the cell's date
    const cellDate = new Date(startDate);
    const cellWeekMonday = this.getLastMondayDate(cellDate);

    // Get the current week's Monday
    const currentWeekStartDate = this.parseDateFromYYYYMMDD(this.currentWeekStartDate);
    const priorWeekStartDate = new Date(currentWeekStartDate);
    priorWeekStartDate.setDate(priorWeekStartDate.getDate() - 7);

    // Compare the Mondays to determine if it's current/past/future week
    const pastWeek = cellWeekMonday < priorWeekStartDate;
    const futureWeek = cellWeekMonday > currentWeekStartDate;
    const editWithoutConfirmation = cellWeekMonday >= priorWeekStartDate &&
      cellWeekMonday <= currentWeekStartDate;

    if (this.debug) {
      console.log('Date calculations:', {
        cellDate,
        cellWeekMonday,
        currentWeekStartDate,
        priorWeekStartDate,
        comparison: {
          isAfterPriorWeek: cellWeekMonday >= priorWeekStartDate,
          isBeforeCurrentWeekEnd: cellWeekMonday <= currentWeekStartDate
        }
      });
    }

    const cellContent = this.createCellContent(metricValue, metricId, isEditable);
    const cellBackgroundColor = metricValue && metricValue.cell_color ? `bg-${metricValue.cell_color}-100` : isEditable ? 'bg-blue-50 text-blue-700' : '';
    const rawValue = metricValue ? metricValue.value : '';

    const baseClasses = `group border border-gray-300 dark:border-gray-600 px-4 py-1.5 text-[11px] text-gray-700 dark:text-white text-center tracking-wide`;
    const editableClass = isEditable ? 'cursor-pointer editable' : '';
    const summaryClass = isSummary ? 'font-bold border-l-4 border-double border-l-gray-400' : '';

    // Ensure that data-metric-value-id is an empty string when there's no metric value ID
    const metricValueId = metricValue && !isSummary && metricValue.id ? metricValue.id : '';

    return `
      <td class="${baseClasses} ${editableClass} ${summaryClass} ${cellBackgroundColor}"
          data-metric-id="${metric.id}"
          data-start-date="${startDate}"
          data-metric-value-id="${metricValueId}"
          data-edit-without-confirmation="${editWithoutConfirmation}"
          data-past-week="${pastWeek}"
          data-future-week="${futureWeek}"
          data-summary="${isSummary}"
          data-cell-value="${rawValue}"
          data-is-editing="${isEditing}"
          ${editableAttributes}>
        ${cellContent}
      </td>
    `;
  }

  createGoalCell(metricId, startDate, goalValue = '', isEditing = false) {
    if (this.debug) console.log('createGoalCell', startDate);
    const editableAttributes = `data-action="click->scorecard-infinite-scroll-table#editCell"`;
    const metric = this.metricsValue.find(m => m.id.toString() === metricId);
    const rawValue = goalValue && goalValue.value
    const formattedValue = this.formatValueByNumberType(rawValue, metric?.number_type);

    return `
      <td class="bg-gray-200 border border-gray-300 dark:border-gray-600 px-4 py-1.5 w-[140px] text-[11px] font-medium text-gray-700 dark:text-white text-center tracking-wide cursor-pointer editable border-r-4 border-double border-r-gray-400"
          data-start-date="${startDate}"
          data-goal="true"
          data-metric-id="${metricId}"
          data-goal-id="${goalValue && goalValue.id || ''}"
          data-cell-value="${rawValue}"
          data-is-editing="${isEditing}"
          ${editableAttributes}>
        ${formattedValue}
      </td>
    `;
  }

  createCellContent(metricValue, metricId, isEditable) {
    if (this.debug) console.log('createCellContent');
    const metric = this.metricsValue.find(m => m.id.toString() === metricId);

    if (metricValue === null ||
      metricValue === undefined ||
      metricValue.value === '' ||
      metricValue.value === null) {
      return isEditable ? '' : '-';
    }

    const formattedValue = this.formatValueByNumberType(metricValue.value, metric?.number_type);
    const infinityIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-infinity"><path d="M12 12c-2-2.67-4-4-6-4a4 4 0 1 0 0 8c2 0 4-1.33 6-4Zm0 0c2 2.67 4 4 6 4a4 4 0 0 0 0-8c-2 0-4 1.33-6 4Z"/></svg>`

    if (metricValue.cell_color && metricValue.tooltip_percentage !== null && metricValue.tooltip_text) {
      return `
            <div class="relative flex items-center justify-center w-full group">
                ${formattedValue}
                <div class="absolute bottom-full left-1/2 mb-2 opacity-0 pointer-events-none transition-opacity duration-150 group-hover:opacity-100 transform -translate-x-1/2 z-10">
                    <div class="relative bg-gray-700 text-white text-xs rounded py-2 px-4 w-48">
                        <div class="mb-1 flex justify-center">${metricValue.tooltip_text == "Infinity%" ? infinityIcon : metricValue.tooltip_text}</div>
                        <div class="w-full bg-gray-300 rounded-full h-2">
                            <div class="bg-${metricValue.cell_color}-500 h-2 rounded-full transition-all duration-500 ease-out" style="width: ${metricValue.tooltip_percentage > 100 ? 100 : metricValue.tooltip_percentage}%"></div>
                        </div>
                        <svg class="absolute text-gray-700 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                    </div>
                </div>
            </div>
        `;
    }

    return formattedValue;
  }

  formatValueByNumberType(value, numberType) {
    if (value === null || value === undefined || value === '') return '';

    const numValue = parseFloat(value);
    if (isNaN(numValue)) return value;

    // Format with commas and appropriate decimals
    const formatNumber = (num, decimals = 0) => {
      const isNegative = num < 0;
      const absNum = Math.abs(num);
      const formattedNum = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      }).format(absNum);
      return isNegative ? `-${formattedNum}` : formattedNum;
    };

    switch (numberType) {
      case 'currency':
        const isNegative = numValue < 0;
        return isNegative ? `-$${formatNumber(Math.abs(numValue), 2)}` : `$${formatNumber(numValue, 2)}`;
      case 'percentage':
        return `${formatNumber(numValue, 2)}%`;
      case 'integer':
        return formatNumber(numValue, 0);
      case 'decimal':
        return formatNumber(numValue, 2);
      default:
        return formatNumber(numValue);
    }
  }

  createHeaderCell(start, end) {
    const formatDate = (date) => date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    return `<th scope="col" class="border border-gray-300 dark:border-gray-600 px-4 py-2 w-[140px] text-xs font-medium text-gray-900 dark:text-white text-center tracking-wide" data-start-date="${this.formatDateToYYYYMMDD(start)}">
      ${formatDate(start)} to ${formatDate(end)}
    </th>`
  }

  createMonthSummaryHeaders(date) {
    const monthName = date.toLocaleDateString('en-US', { month: 'long' })
    return `
      <th scope="col" class="border border-gray-300 dark:border-gray-600 px-4 py-2 w-[140px] text-xs font-medium text-gray-900 dark:text-white text-center tracking-wide border-l-4 border-double border-l-gray-400">${monthName} Actuals</th>
      <th scope="col" class="bg-gray-200 border border-gray-300 dark:border-gray-600 px-4 py-2 w-[140px] text-xs font-medium text-gray-900 dark:text-white text-center tracking-wide border-r-4 border-double border-r-gray-400">${monthName} Goals</th>
    `
  }

  getNextMonth(date) {
    if (this.debug) console.log('getNextMonth');
    return new Date(date.getFullYear(), date.getMonth() + 1, 1)
  }

  getPreviousMonth(date) {
    if (this.debug) console.log('getPreviousMonth');
    return new Date(date.getFullYear(), date.getMonth() - 1, 1)
  }

  getCurrentMonth() {
    if (this.debug) console.log('getCurrentMonth');
    return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1)
  }

  setupInfiniteScroll() {
    if (this.debug) console.log('setupInfiniteScroll');
    const container = this.element.querySelector('.overflow-x-auto');
    let lastScrollLeft = container.scrollLeft;

    container.addEventListener('scroll', () => {
      if (this.isLoading) return;

      const currentScrollLeft = container.scrollLeft;
      const scrollingRight = currentScrollLeft > lastScrollLeft;
      const scrollingLeft = currentScrollLeft < lastScrollLeft;

      if (container.scrollLeft + container.clientWidth >= container.scrollWidth - 500) {
        this.loadMoreContent('future');
      } else if (currentScrollLeft <= 400 && scrollingLeft) {
        this.loadMoreContent('past');
      }

      this.updateYearDisplay();
      this.updateThisWeekButtonState();
      lastScrollLeft = currentScrollLeft;
    });
  }

  async loadMoreContent(direction) {
    if (this.debug) console.log('loadMoreContent');
    if (this.isLoading) return; // Exit if already loading

    this.isLoading = true; // Set flag to true

    let newDate
    if (direction === 'future') {
      newDate = this.getNextMonth(this.latestGeneratedDate)
    } else {
      newDate = this.getPreviousMonth(this.earliestGeneratedDate)
    }

    try {
      await this.generateMonthContent(newDate, direction)

      if (direction === 'past') {
        // Adjust scroll position to keep the view stable
        const container = this.element.querySelector('.overflow-x-auto')
        const newCellWidth = container.querySelector('th:not(:first-child)').offsetWidth
        const newCellCount = this.getMonthCellCount(newDate)
        container.scrollLeft += newCellWidth * newCellCount
      }

      this.updateYearDisplay()
    } catch (error) {
      console.error('Error loading more content:', error)
    } finally {
      this.isLoading = false; // Reset flag when done
    }
  }

  // Add this helper method to calculate the number of cells for a month
  getMonthCellCount(date) {
    if (this.debug) console.log('getMonthCellCount');
    const year = date.getFullYear()
    const month = date.getMonth()
    const lastDay = new Date(year, month + 1, 0).getDate()
    let cellCount = Math.ceil(lastDay / 7) // Weekly cells
    cellCount += 2 // Add 2 for month summary and goal cells
    return cellCount
  }

  updateYearDisplay() {
    if (this.debug) console.log('updateYearDisplay');
    const visibleHeaders = this.getVisibleHeaders()
    if (visibleHeaders.length > 0) {
      const middleHeader = visibleHeaders[Math.floor(visibleHeaders.length / 2)]
      if (middleHeader.dataset.startDate) {
        const startDate = new Date(middleHeader.dataset.startDate)
        const year = startDate.getFullYear()
        this.yearDisplayTarget.textContent = year
      }
    }
  }

  getVisibleHeaders() {
    if (this.debug) console.log('getVisibleHeaders');
    const container = this.element.querySelector('.overflow-x-auto')
    const headers = Array.from(this.headerRowTarget.querySelectorAll('th:not(:first-child)'))
    return headers.filter(header => {
      const rect = header.getBoundingClientRect()
      return rect.left >= container.getBoundingClientRect().left && rect.right <= container.getBoundingClientRect().right
    })
  }

  editCell(event) {
    if (this.debug) console.log('editCell');
    const cell = event.currentTarget;
    if (cell.querySelector('input')) return;

    // Store the original cell content including tooltip
    cell.dataset.originalContent = cell.innerHTML;

    // Update active cell tracking
    const row = cell.closest('tr');
    const allCells = Array.from(row.cells);
    const allRows = Array.from(this.metricRowTargets);

    this.activeCell = {
      rowIndex: allRows.indexOf(row),
      cellIndex: allCells.indexOf(cell)
    };

    // Set cell to editing mode - this will trigger the observer
    cell.dataset.isEditing = 'true';
  }

  setupCellInput(cell) {
    // If input already exists, don't set up again
    if (cell.querySelector('input')) return;

    // Store original content if not already stored
    if (!cell.dataset.originalContent) {
      cell.dataset.originalContent = cell.innerHTML;
    }

    const currentValue = cell.dataset.cellValue;
    const metricId = cell.dataset.metricId;
    const metric = this.metricsValue.find(m => m.id.toString() === metricId);

    const input = document.createElement('input');
    input.type = 'number';

    // Set appropriate step values for better UX while editing
    switch (metric?.number_type) {
      case 'currency':
      case 'decimal':
      case 'percentage':
        input.step = '0.01';
        break;
      case 'integer':
        input.step = '1';
        break;
      default:
        input.step = 'any';
    }

    // Remove any formatting symbols before setting the value
    input.value = currentValue ? currentValue.replace(/[$%,]/g, '') : '';
    input.className = 'w-full h-[31px] text-center text-xs border-none bg-primary-100 dark:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500';

    // Clear any other cells that are being edited
    const editingCells = this.element.querySelectorAll('td[data-is-editing="true"]');
    editingCells.forEach(editingCell => {
      if (editingCell !== cell) {
        this.cancelEdit(editingCell);
      }
    });

    // Clear the cell content (including tooltip)
    cell.textContent = '';
    cell.appendChild(input);
    input.focus();
    input.select();
    cell.style.padding = '0';

    const handleBlur = () => {
      input.removeEventListener('blur', handleBlur);
      this.handleCellEdit(cell, input, false);
    };

    input.addEventListener('blur', handleBlur);
    input.addEventListener('keydown', (e) => this.handleInputKeydown(cell, input, handleBlur, e));
    input.addEventListener('mousedown', (e) => e.stopPropagation());
    input.addEventListener('click', (e) => e.stopPropagation());
  }

  handleInputKeydown(cell, input, handleBlur, e) {
    if (this.debug) console.log('handleInputKeydown');

    const handleNavigation = (direction) => {
      input.removeEventListener('blur', handleBlur);
      this.handleCellEdit(cell, input, false)
        .then(() => {
          return this.findCellAfterRefresh();
        })
        .then((refreshedCell) => {
          // If we found the cell after refresh, navigate from there
          const navigationCell = refreshedCell || cell;
          this.navigateToCell(navigationCell, direction);
        })
        .catch(() => {
          // If there's an error, keep focus on current cell
          cell.click();
        });
    };

    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        handleNavigation('down');
        break;

      case 'Tab':
        e.preventDefault();
        handleNavigation(e.shiftKey ? 'left' : 'right');
        break;

      case 'Escape':
        e.preventDefault();
        input.removeEventListener('blur', handleBlur);
        this.cancelEdit(cell);
        break;
    }
  }

  parseDateFromYYYYMMDD(dateString) {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  handleCellEdit(cell, input, fromModal = false) {
    if (this.debug) console.log('handleCellEdit');
    const newValue = input.value.trim();
    const oldValue = cell.dataset.cellValue;
    const isGoal = cell.dataset.goal === 'true';

    if (newValue === oldValue) {
      this.cancelEdit(cell);
      return Promise.resolve(cell);
    }

    if (isGoal) {
      return this.saveGoalCell(cell, input);
    } else if (fromModal || (!this.shouldSkipConfirmation() && cell.dataset.editWithoutConfirmation == 'false')) {
      if (fromModal) {
        return this.saveCell(cell, input);
      } else {
        const message = cell.dataset.pastWeek == 'true'
          ? "You're changing data for a prior week that's already closed. This will also update the Weekly Business Review data for <span id='wbrDate'></span>. Are you sure you want to proceed?"
          : "You're entering data for a week that hasn't started yet. Are you sure you want to proceed?";
        return new Promise((resolve, reject) => {
          this.showEditConfirmationModal(cell, input, new Date(cell.dataset.startDate), message, resolve, reject);
        });
      }
    } else {
      return this.saveCell(cell, input);
    }
  }

  showEditConfirmationModal(cell, input, startDate, message, resolve, reject) {
    if (this.debug) console.log('showEditConfirmationModal');
    this.editConfirmationMessageTarget.innerHTML = message;
    if (this.editConfirmationModalTarget.querySelector('#wbrDate')) {
      this.editConfirmationModalTarget.querySelector('#wbrDate').textContent = this.formatDateToYYYYMMDD(startDate);
    }

    const proceedButton = this.editConfirmationModalTarget.querySelector('#proceed-with-edit-modal-button');
    const cancelButton = this.editConfirmationModalTarget.querySelector('#cancel-edit-modal-button');
    const closeButton = this.editConfirmationModalTarget.querySelector('#close-edit-modal-button');
    const dontShowAgainCheckbox = this.editConfirmationModalTarget.querySelector('#dontShowAgain');

    const handleProceed = () => {
      if (dontShowAgainCheckbox.checked) {
        this.setLastConfirmationTime(Date.now());
      }
      this.handleCellEdit(cell, input, true)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          this.hideEditConfirmationModal();
          this.cleanupModalListeners(proceedButton, cancelButton, closeButton, handleProceed, handleCancel);
        });
    };

    const handleCancel = () => {
      this.cancelEdit(cell);
      this.hideEditConfirmationModal();
      this.cleanupModalListeners(proceedButton, cancelButton, closeButton, handleProceed, handleCancel);
      reject(new Error('Edit cancelled'));
    };

    proceedButton.addEventListener('click', handleProceed);
    cancelButton.addEventListener('click', handleCancel);
    closeButton.addEventListener('click', handleCancel);

    // Show the modal and focus on the proceed button
    this.editConfirmationModal.show();

    // First blur the input to remove its focus
    input.blur();

    // Then focus on the proceed button after a short delay
    setTimeout(() => {
      proceedButton.focus();
    }, 50);
  }

  hideEditConfirmationModal() {
    if (this.debug) console.log('hideEditConfirmationModal');
    this.editConfirmationModal.hide();
  }

  cleanupModalListeners(proceedButton, cancelButton, closeButton, handleProceed, handleCancel) {
    if (this.debug) console.log('cleanupModalListeners');
    proceedButton.removeEventListener('click', handleProceed);
    cancelButton.removeEventListener('click', handleCancel);
    closeButton.removeEventListener('click', handleCancel);
  }

  getLastMondayDate(date) {
    const dayOfWeek = date.getDay()
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
    return new Date(date.setDate(diff))
  }

  shouldSkipConfirmation() {
    if (this.debug) console.log('shouldSkipConfirmation');
    return Date.now() - this.lastConfirmationTime < 5 * 60 * 1000 // 5 minutes in milliseconds
  }

  validateFloatInput(event) {
    if (this.debug) console.log('validateFloatInput');
    const input = event.target
    const value = input.value

    // Remove any non-numeric characters except for the first decimal point
    let sanitizedValue = value.replace(/[^\d.]/g, '')
    const parts = sanitizedValue.split('.')
    if (parts.length > 2) {
      sanitizedValue = parts[0] + '.' + parts.slice(1).join('')
    }

    // Ensure only two decimal places
    const decimalIndex = sanitizedValue.indexOf('.')
    if (decimalIndex !== -1) {
      sanitizedValue = sanitizedValue.slice(0, decimalIndex + 3)
    }

    // Remove trailing zeros after decimal point and remove decimal if no decimal places
    sanitizedValue = sanitizedValue.replace(/\.?0+$/, '')

    // Update the input value if it has changed
    if (sanitizedValue !== value) {
      input.value = sanitizedValue
    }
  }

  saveCell(cell, input) {
    if (this.debug) console.log('saveCell');
    const newValue = input.value.trim();
    const metricId = cell.dataset.metricId;
    const startDate = cell.dataset.startDate;
    const metricValueId = cell.dataset.metricValueId;
    const metric = this.metricsValue.find(m => m.id.toString() === metricId);

    if (newValue === '') {
      if (metricValueId) {
        return this.deleteMetricValue(metricValueId)
          .then(() => {
            cell.textContent = '';
            cell.dataset.metricValueId = '';
            cell.dataset.cellValue = '';
            return this.determineRefreshMonths(metricId, startDate);
          });
      } else {
        cell.textContent = '';
        cell.dataset.cellValue = '';
        return Promise.resolve();
      }
    }

    const parsedValue = this.parseValueForSaving(newValue, metric?.number_type);
    if (parsedValue === null) {
      console.error('Invalid input for metric type:', metric?.number_type);
      this.cancelEdit(cell);
      return Promise.reject(new Error('Invalid input for metric type'));
    }

    const saveOperation = metricValueId
      ? this.updateMetricValue(metricId, metricValueId, parsedValue)
      : this.createMetricValue(metricId, startDate, parsedValue);

    return saveOperation
      .then((data) => {
        cell.textContent = this.formatValueByNumberType(parsedValue, metric?.number_type);
        cell.dataset.cellValue = parsedValue.toString();
        if (data.id) {
          cell.dataset.metricValueId = data.id;
        }
        return cell;
      }).then(() => {
        this.determineRefreshMonths(metricId, startDate);
      })
      .catch((error) => {
        console.error('Error saving value:', error);
        this.cancelEdit(cell);
        if (this.hasToastTarget) {
          this.showToast('error', error.message);
        } else {
          alert(error.message);
        }
        throw error;
      });
  }

  parseValueForSaving(value, numberType) {
    if (this.debug) console.log('parseValueForSaving');
    // Just strip formatting characters and return the numeric value
    const cleanValue = value.replace(/[$%,]/g, '');
    const numValue = parseFloat(cleanValue);

    if (isNaN(numValue)) return null;
    return numValue;
  }

  updateCellsForMonth(metricId, startDate, endDate, metricValues) {
    if (this.debug) console.log('updateCellsForMonth');
    const cells = this.element.querySelectorAll(`td[data-metric-id="${metricId}"]`);
    cells.forEach(cell => {
      const cellDate = new Date(cell.dataset.startDate);
      if (cellDate >= startDate && cellDate <= endDate) {
        const formattedDate = this.formatDateToYYYYMMDD(cellDate);
        const isGoal = cell.dataset.goal === 'true';
        const isSummary = cell.dataset.summary === 'true';
        const metricValue = metricValues[metricId] && (isGoal ? metricValues[metricId][formattedDate + '-goal'] : isSummary ? metricValues[metricId][formattedDate + '-aggregation'] : metricValues[metricId][formattedDate]);
        this.updateCellContent(cell, metricValue || { value: '' });
      }
    });
  }

  updateCellContent(cell, metricValue) {
    if (this.debug) console.log('updateCellContent');
    const metricId = cell.dataset.metricId;
    const startDate = cell.dataset.startDate;
    const isSummary = cell.dataset.summary === 'true';
    const isGoal = cell.dataset.goal === 'true';
    const isEditing = cell.dataset.isEditing === 'true';

    if (isGoal) {
      cell.outerHTML = this.createGoalCell(metricId, startDate, metricValue, isEditing);
    } else {
      cell.outerHTML = this.createCell(metricId, startDate, {
        ...metricValue,
        metric_id: metricId // Add metric_id to the metricValue object
      }, isSummary, isEditing);
    }
  }

  cancelEdit(cell) {
    if (this.debug) console.log('cancelEdit');

    // Remove the input element
    const input = cell.querySelector('input');
    if (input) {
      input.remove();
    }

    // Restore the original cell content including tooltip
    if (cell.dataset.originalContent) {
      cell.innerHTML = cell.dataset.originalContent;
      delete cell.dataset.originalContent;
      cell.style.padding = ''; // Reset padding
    }

    // Reset editing state
    cell.dataset.isEditing = 'false';
  }

  updateMetricValue(metricId, metricValueId, value) {
    if (this.debug) console.log('updateMetricValue');
    return fetch('/metrics/' + metricId + '/metric_values/' + metricValueId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      },
      cache: 'no-store',
      body: JSON.stringify({ metric_value: { value } })
    })
      .then(async response => {
        const data = await response.json();
        if (data.flash_html) {
          const flashesDiv = document.getElementById('flashes');
          flashesDiv.innerHTML = data.flash_html;
        }
        return data;
      })
      .catch((error) => {
        // You might want to show this error to the user via a toast or alert
        console.error('Error updating metric value:', error.message);
        throw error;
      });
  }

  createMetricValue(metricId, businessDate, value) {
    if (this.debug) console.log('createMetricValue');
    return fetch('/metrics/' + metricId + '/metric_values', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        metric_value: {
          metric_id: metricId,
          business_date: businessDate,
          value: value
        }
      })
    })
      .then(async response => {
        const data = await response.json();
        if (data.flash_html) {
          const flashesDiv = document.getElementById('flashes');
          flashesDiv.innerHTML = data.flash_html;
        }
        return data;
      })
      .catch((error) => {
        throw error
      })
  }

  async fetchMetricValues(startDate, endDate, metricIds = []) {
    if (this.debug) console.log('fetchMetricValues');
    const url = `/scorecards/${this.scorecardIdValue}/metrics_overview?start_date=${this.formatDateToYYYYMMDD(startDate)}&end_date=${this.formatDateToYYYYMMDD(endDate)}&metric_ids=${metricIds.join(',')}`

    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        },
        cache: 'no-store'
      })
      if (!response.ok) throw new Error('Network response was not ok')
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error fetching metric values:', error)
      return {}
    }
  }

  scrollToCurrentWeek() {
    if (this.debug) console.log('scrollToCurrentWeek');
    const container = this.element.querySelector('.overflow-x-auto')
    const currentWeekHeader = this.headerRowTargets[0].querySelector(`th[data-start-date^="${this.currentWeekStartDate}"]`)
    const stickyColumnWidth = this.element.querySelector('.sticky.left-0').offsetWidth;

    if (this.debug) {
      console.log('Centering calculations:', {
        currentWeekStartDate: this.currentWeekStartDate,
        containerWidth: container.clientWidth,
        currentWeekHeaderWidth: currentWeekHeader?.offsetWidth,
        currentWeekHeaderLeft: currentWeekHeader?.offsetLeft,
        stickyColumnWidth: stickyColumnWidth
      });
    }

    if (currentWeekHeader) {
      const containerWidth = container.clientWidth;
      const headerWidth = currentWeekHeader.offsetWidth;
      const headerPosition = currentWeekHeader.offsetLeft;

      // Calculate position to center the middle of the column
      const scrollPosition = (headerPosition + (headerWidth / 2)) - stickyColumnWidth - ((containerWidth - stickyColumnWidth) / 2);
      if (this.debug) {
        console.log('Scroll calculations:', {
          headerPosition,
          scrollPosition,
          finalScrollPosition: Math.max(0, scrollPosition)
        });
      }

      container.scrollLeft = Math.max(0, scrollPosition);
      this.updateThisWeekButtonState()
    }

    // Enable the button after scrolling
    this.enableThisWeekButton()
  }

  getCurrentWeekStartDate() {
    if (this.debug) console.log('getCurrentWeekStartDate');

    const now = new Date()
    const monday = new Date(now)
    const daysSinceMonday = (monday.getDay() + 6) % 7
    monday.setDate(monday.getDate() - daysSinceMonday)

    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const weekStartDate = monday < firstDayOfMonth ? firstDayOfMonth : monday

    return this.formatDateToYYYYMMDD(weekStartDate)
  }

  formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  updateThisWeekButtonState() {
    if (this.debug) console.log('updateThisWeekButtonState');
    const container = this.element.querySelector('.overflow-x-auto');
    const currentWeekHeader = this.headerRowTargets[0].querySelector(`th[data-start-date^="${this.getCurrentWeekStartDate()}"]`);
    const stickyColumnWidth = this.element.querySelector('.sticky-column')?.offsetWidth || 0;

    if (currentWeekHeader) {
      const containerWidth = container.clientWidth;
      const headerWidth = currentWeekHeader.offsetWidth;
      const headerPosition = currentWeekHeader.offsetLeft;
      const headerCenter = headerPosition + (headerWidth / 2);
      const visibleAreaCenter = container.scrollLeft + stickyColumnWidth + ((containerWidth - stickyColumnWidth) / 2);
      const difference = Math.abs(headerCenter - visibleAreaCenter);

      if (difference <= 300) {
        this.disableThisWeekButton();
      } else {
        this.enableThisWeekButton();
      }
    }
  }

  disableThisWeekButton() {
    if (this.debug) console.log('disableThisWeekButton');
    this.thisWeekButtonTarget.disabled = true;
    this.thisWeekButtonTarget.classList.remove('bg-primary-50', 'ring-primary-300', 'hover:bg-primary-100');
    this.thisWeekButtonTarget.classList.add('bg-white', 'ring-gray-200', 'cursor-not-allowed');
  }

  enableThisWeekButton() {
    if (this.debug) console.log('enableThisWeekButton');
    this.thisWeekButtonTarget.disabled = false;
    this.thisWeekButtonTarget.classList.remove('bg-white', 'ring-gray-200', 'cursor-not-allowed');
    this.thisWeekButtonTarget.classList.add('bg-primary-50', 'ring-primary-300', 'hover:bg-primary-100');
  }

  getLastConfirmationTime() {
    if (this.debug) console.log('getLastConfirmationTime');
    const storedTime = localStorage.getItem('lastConfirmationTime');
    return storedTime ? parseInt(storedTime, 10) : 0;
  }

  setLastConfirmationTime(time) {
    if (this.debug) console.log('setLastConfirmationTime');
    localStorage.setItem('lastConfirmationTime', time.toString());
    this.lastConfirmationTime = time;
  }

  saveGoalCell(cell, input) {
    if (this.debug) console.log('saveGoalCell');
    const newValue = input.value.trim();
    const metricId = cell.dataset.metricId;
    const startDate = cell.dataset.startDate;
    const goalId = cell.dataset.goalId;
    const metric = this.metricsValue.find(m => m.id.toString() === metricId);

    // Handle empty value case
    if (newValue === '') {
      if (goalId) {
        return this.deleteMetricGoal(metricId, goalId)
          .then(() => {
            cell.textContent = '';
            cell.dataset.goalId = '';
            cell.dataset.cellValue = '';
            return this.determineRefreshMonths(metricId, startDate);
          });
      } else {
        cell.textContent = '';
        cell.dataset.cellValue = '';
        return Promise.resolve(cell);
      }
    }

    // Parse and validate the value
    const parsedValue = this.parseValueForSaving(newValue, metric?.number_type);
    if (parsedValue === null) {
      console.error('Invalid input for metric type:', metric?.number_type);
      this.cancelEdit(cell);
      return Promise.reject(new Error('Invalid input for metric type'));
    }

    // Determine and execute save operation
    const saveOperation = goalId
      ? this.updateMetricGoal(metricId, goalId, parsedValue)
      : this.createMetricGoal(metricId, startDate, parsedValue);

    return saveOperation
      .then((response) => {
        cell.textContent = this.formatValueByNumberType(parsedValue, metric?.number_type);
        cell.dataset.goalId = response.id;
        cell.dataset.cellValue = parsedValue.toString();
        return this.determineRefreshMonths(metricId, startDate);
      })
      .then(() => cell);
  }

  updateMetricGoal(metricId, goalId, value) {
    if (this.debug) console.log('updateMetricGoal');
    return fetch(`/metrics/${metricId}/metric_goals/${goalId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ metric_goal: { value } })
    })
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  }

  createMetricGoal(metricId, goalDate, value) {
    if (this.debug) console.log('createMetricGoal');
    return fetch(`/metrics/${metricId}/metric_goals`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        metric_goal: {
          metric_id: metricId,
          goal_date: goalDate,
          value: value
        }
      })
    })
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteMetricGoal(metricId, goalId) {
    if (this.debug) console.log('deleteMetricGoal');
    return fetch('/metrics/' + metricId + '/metric_goals/' + goalId, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  }

  determineRefreshMonths(metricId, startDate) {
    if (this.debug) console.log('determineRefreshMonths');
    const metric = this.metricsValue.find(m => m.id.toString() === metricId);

    if (metric.has_derived_metrics) {
      const nextMonth = new Date(startDate);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      this.refreshMonth(metricId, nextMonth);
    }
    this.refreshMonth(metricId, startDate);
  }

  refreshMonth(metricId, startDate) {
    if (this.debug) console.log('refreshMonth');
    const date = new Date(startDate);
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return this.fetchMetricValues(firstDayOfMonth, lastDayOfMonth, [metricId])
      .then((metricValues) => {
        Object.keys(metricValues).forEach(metricId => {
          this.updateCellsForMonth(metricId, firstDayOfMonth, lastDayOfMonth, metricValues);
        });
        return this.findCellAfterRefresh();
      })
      .catch((error) => {
        console.error('Error refreshing month:', error);
        throw error;
      });
  }

  findCellAfterRefresh() {
    if (!this.activeCell) return null;

    const allRows = Array.from(this.metricRowTargets);
    const targetRow = allRows[this.activeCell.rowIndex];
    if (!targetRow) return null;

    const allCells = Array.from(targetRow.cells);
    return allCells[this.activeCell.cellIndex];
  }

  deleteMetricValue(metricValueId) {
    if (this.debug) console.log('deleteMetricValue');
    // Find the metric ID from the cell with this metric value ID
    const cell = this.element.querySelector(`td[data-metric-value-id="${metricValueId}"]`);
    if (!cell) {
      throw new Error('Could not find cell for metric value');
    }
    const metricId = cell.dataset.metricId;

    return fetch(`/metrics/${metricId}/metric_values/${metricValueId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(async response => {
        const data = await response.json();
        if (data.flash_html) {
          const flashesDiv = document.getElementById('flashes');
          flashesDiv.innerHTML = data.flash_html;
        }
        return data;
      })
      .catch((error) => {
        throw error;
      });
  }

  navigateToCell(currentCell, direction) {
    if (this.debug) console.log('navigateToCell', direction);

    const row = currentCell.closest('tr');
    const allCells = Array.from(row.cells);
    const allRows = Array.from(this.metricRowTargets);
    let currentIndex = allCells.indexOf(currentCell);
    let currentRowIndex = allRows.indexOf(row);

    let nextCell;
    let attempts = 0;
    const maxAttempts = allRows.length * allCells.length; // Prevent infinite loops

    while (!nextCell && attempts < maxAttempts) {
      switch (direction) {
        case 'right':
          currentIndex++;
          if (currentIndex >= allCells.length) {
            currentIndex = 0;
            currentRowIndex = (currentRowIndex + 1) % allRows.length;
          }
          break;
        case 'left':
          currentIndex--;
          if (currentIndex < 0) {
            currentIndex = allCells.length - 1;
            currentRowIndex = (currentRowIndex - 1 + allRows.length) % allRows.length;
          }
          break;
        case 'down':
          currentRowIndex = (currentRowIndex + 1) % allRows.length;
          break;
      }

      // Get the candidate next cell
      const candidateRow = allRows[currentRowIndex];
      const candidateCell = candidateRow?.cells[currentIndex];

      // Check if this cell is valid for editing
      if (candidateCell &&
        candidateCell.dataset.summary !== 'true' &&
        !candidateCell.classList.contains('sticky') &&
        candidateCell.classList.contains('editable')) {
        nextCell = candidateCell;
      }

      attempts++;
    }

    // If we found a valid cell, navigate to it
    if (nextCell) {
      // Store the active cell position
      this.activeCell = {
        rowIndex: currentRowIndex,
        cellIndex: currentIndex
      };

      // Trigger click on the next cell to start editing
      nextCell.click();

      // Ensure the next cell is visible by scrolling if necessary
      this.ensureCellIsVisible(nextCell);
    }
  }

  ensureCellIsVisible(cell) {
    if (this.debug) console.log('ensureCellIsVisible');

    const container = this.element.querySelector('.overflow-x-auto');
    const cellRect = cell.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    // Check if cell is outside the visible area
    if (cellRect.right > containerRect.right) {
      // Scroll right to show the cell
      container.scrollLeft += (cellRect.right - containerRect.right + 50); // 50px buffer
    } else if (cellRect.left < containerRect.left) {
      // Scroll left to show the cell
      container.scrollLeft -= (containerRect.left - cellRect.left + 50); // 50px buffer
    }
  }
}
