import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Set timeout to remove flash after 5 seconds
    setTimeout(() => {
      this.element.remove()
    }, 5000)
  }

  remove() {
    this.element.remove()
  }
} 
